import React from 'react';
import styled from 'styled-components';

import TopicContainer from '../components/layout/TopicContainer';
import Sidebar from '../components/Sidebar';
import Admonition from '../components/topic/Admonition';
import H2 from '../components/typography/H2';
import H3 from '../components/typography/H3';
import useGetTopic from '../hooks/useGetTopic';
import useSidebar from '../hooks/useSidebar';
import P from '../components/typography/P';

const Om = () => {
	const topicId = "D020662";

	const { getTopic } = useGetTopic();
	const { getSidebar } = useSidebar();

	return ( 
		<>
			<TopicContainer>
				{getTopic(topicId)}
				<H2>GEARBOX OIL LEVEL</H2>
				<Admonition type="caution"><P>Allowing the gearbox oil level to run low will cause damage to the gearbox. Check levels regularly and adjust as necessary.</P></Admonition>
				<P>The gearbox oil level should be checked weekly and adjusted if required. Follow the instructions below to check and adjust the gearbox oil level.</P>
				{getTopic("D019938", true)}
			</TopicContainer>
			<Sidebar>
				{getSidebar(topicId)}
			</Sidebar>
		</>
	 );
}
 
export default Om;