import React from 'react';
import styled from 'styled-components';

const List = styled.ol`
	columns: 2;
	-webkit-columns: 2;
  	-moz-columns: 2;
	padding-left: 20px;
`;

const Legend = ({ children }) => {
	return ( 
		<>
			<List>
				{ children }
			</List>
		</>
	 );
}
 
export default Legend;