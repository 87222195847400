import React, { useContext } from 'react';

import TopicContainer from '../components/layout/TopicContainer';
import Sidebar from '../components/Sidebar';
import SidebarPanel from '../components/SidebarPanel';
import SidebarPanelLink from '../components/SidebarPanelLink';
import DocTypeIcon from '../components/DocTypeIcon';
import useGetTopic from '../hooks/useGetTopic';
import { useParams } from 'react-router-dom';
import { AuthContext, ComingSoonContext, SelectorContext } from '../App';
import topics from '../data/topics';
import TopicValidator from '../components/TopicValidator';
import useGetSidebar from '../hooks/useSidebar';

const ServiceInformationTopic = () => {

	const menuContext = useContext(SelectorContext);
	const { setSiPage } = useContext(AuthContext);
	const { toggleComingSoon } = useContext(ComingSoonContext);
	const { getTopic } = useGetTopic();
	const { getSidebar } = useGetSidebar();
	const { topicId } = useParams();
	const meta = topics.filter(topic => topic.id === topicId)[0];

	React.useEffect(() => {
		menuContext.setBreadcrumb(meta.breadcrumb);
		setSiPage(window.location.pathname);
	}, [topicId])

	return ( 
		<>
			<TopicContainer>
				{getTopic(topicId)}
			</TopicContainer>
			<Sidebar>
				{getSidebar(topicId)}
			</Sidebar>
		</>
		
	 );
}
 
export default ServiceInformationTopic;