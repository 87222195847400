import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const Container = styled.div`
	display: flex;
	height: 57px;
`;

const Qty = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.GreySuperLight};
	width: 70px;
	overflow: hidden;
`;

const Number = styled.span`
	font-family: ${({ theme }) => theme.fonts.title};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
	font-size: ${({ theme }) => theme.fontSizes.l};
`;

const CartQty = ({ quantity, onIncrease, onDecrease, partNumber }) => {

	
	return ( 
		<Container>
			
			<Button type="outline" onClick={() => onDecrease(partNumber)}>-</Button>
			<Qty>
				<Number>{quantity}</Number>
			</Qty>
			<Button type="outline" onClick={() => onIncrease(partNumber)}>+</Button>
		</Container>
	 );
}
 
export default CartQty;