import styled from 'styled-components';

export default styled.h3`
	font-family: ${({ theme }) => theme.fonts.title};
	font-size: ${({ theme }) => theme.fontSizes.l};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
	line-height: ${({ theme }) => theme.lineHeights.l};
	color: ${({ theme }) => theme.colors.Black};
	margin-top: 60px;
	letter-spacing: ${({ theme }) => theme.letterSpacings.s};
`;