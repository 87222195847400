import React, { useContext } from 'react';

import useAuth from "../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { AuthContext, BrandContext } from '../App';

const Logout = () => {
	const { changeBrand } = useContext(BrandContext);
	const { logout } = useContext(AuthContext);
	const navigate = useNavigate();
	console.log("Changing brand", "agco");
	changeBrand("agco");
	logout();
	React.useEffect(() => {navigate('/login');}, []);
}
 
export default Logout;