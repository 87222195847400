import React, { useContext } from 'react';
import styled from 'styled-components';
import SelectorMenu from './SelectorMenu';
import ARoute from './typography/ARoute';
import { AuthContext, SelectorContext } from '../App';
import { useNavigate, useParams } from 'react-router-dom';

const Bar = styled.div`
	margin-top: 16px;
	background-color: #f0f0f0;//${({ theme }) => theme.colors.GreySuperLight};
	height: 41px; 
	margin-right: 186px;
`;

const Container = styled.div`
	//display: flex;
	position: relative;
	padding: 10px 10px 10px 10px;
	margin: auto;
`;

const MenuIcon = styled.img`
	display: inline;
	margin-top: -2px;
	cursor: pointer;
	margin-right: 154px;
	`;
	
	const HomeIcon = styled.img`
	display: inline;
	margin-top: -2px;
	margin-right: 40px;
	margin-left: 20px;
	cursor: pointer;
	width: 24px;
`;

const Breadcrumb = styled.div`
	display: inline-block;
`;

const Item = styled(ARoute)`

	font-family: ${({ theme }) => theme.fonts.regular};
	color: ${({ theme }) => theme.colors.GreyDark};
`;

const DeadItem = styled.span`
	font-family: ${({ theme }) => theme.fonts.regular};
	color: ${({ theme }) => theme.colors.GreyDark};
	font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const Separator = styled.img`
	display: inline;
	margin-left: 15px;
	margin-right: 15px;
	margin-top: -2px;
`;

const SelectorBar = () => {
	
	const { id1, id2, id3, id4 } = useParams();

	const menuContext = useContext(SelectorContext);
	const bc = menuContext.breadcrumb;
	const navigate = useNavigate();
	const { siPage, setSiPage } = useContext(AuthContext);

	React.useEffect(() => {
		menuContext.updateSelector(id1, id2, id3, id4);
	}, [id1, id2, id3, id4]);

	function handleHomeClick() {
		setSiPage(null);
		navigate('/si');
	}

	return ( 
		<>
			<Bar>
				<Container>
					<HomeIcon src="/images/icons/icon-home-greydark.svg" onClick={ handleHomeClick } />
					<MenuIcon src="/images/icons/icon-menu-greydark.svg" onClick={ menuContext.toggleSelector } />
					<Breadcrumb>
						{ bc[0] && (
							<Item to={bc[0].href} >{ bc[0].id !== null ? `${bc[0].id}. ${bc[0].name}` : bc[0].name }</Item>
						)}
						{ bc[1] && (
							<>
								<Separator src="/images/icons/icon-chevron-right-greydark.svg" />
								<Item to={bc[1].href}>{ bc[1].id !== null ? `${bc[1].id}. ${bc[1].name}` : bc[1].name }</Item>
							</>
						)}
						{ bc[2] && (
							<>
								<Separator src="/images/icons/icon-chevron-right-greydark.svg" />
								<Item to={bc[2].href}>{ bc[2].id !== null ? `${bc[2].id}. ${bc[2].name}` : bc[2].name }</Item>
							</>
						)}
						{ bc[3] && (
							<>
								<Separator src="/images/icons/icon-chevron-right-greydark.svg" />
								<DeadItem>{ `${bc[3].name}` }</DeadItem>
							</>
						)}
					</Breadcrumb>
					{ menuContext.selectorVisible && (
						<SelectorMenu />
					)}
				</Container>
			</Bar>
		</>
	 );
}
 
export default SelectorBar;