import React from 'react';
import styled from 'styled-components';

import P from '../components/typography/P';
import Procedure from '../components/topic/Procedure';
import Step from '../components/topic/Step';
import Figure from '../components/topic/Figure';
import Admonition from '../components/topic/Admonition';
import PageHeading from '../components/PageHeading';
import YouTube from 'react-youtube';
import Spacer from '../components/layout/Spacer';
import Schematic from '../components/Schematic';
import IncludedTopic from '../components/topic/IncludedTopic';
import ARoute from '../components/typography/ARoute';
import Table from '../components/topic/Table';
import BulletList from '../components/topic/BulletList';
import useComingSoon from './useComingSoon';
import A from '../components/typography/A';
import { ComingSoonContext } from '../App'
import ComingSoon from '../components/ComingSoon';
import TransformWrapperHelper from '../components/TransformWrapperHelper';
import ImageMap from '../components/ImageMap';
import ImageMapItem from '../components/ImageMapItem';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SchematicT120186 } from '../images/T120186_en-GB.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Legend from '../components/topic/Legend';
import LegendItem from '../components/topic/LegendItem';
import H3 from '../components/typography/H3';

const useGetTopic = () => {

	function pauseVideo(event) {
		event.target.pauseVideo();
	}

	const { toggleComingSoon, comingSoonVisible } = React.useContext(ComingSoonContext);
	const navigate = useNavigate();

	function getTopic(topicId, embed = false) {

		switch (topicId) {
			case "D019938":
				return (
					<>
						{embed === false && (
							<PageHeading title="BRAKE CONTROL UNIT - ACCESS" subtitle="REMOVE AND INSTALL" />
						)}
						<Procedure title="REMOVE">
							<Step>
								<IncludedTopic title="Side Skirts" subtitle="Remove and Install" toggleComingSoon={toggleComingSoon} />
							</Step>
							<Step>
								<P>Use a BCU access key (T1) to unlock the 6 fasteners.</P>
								<Admonition type="warning">
									<P>Ensure that the access panel is supported. Failure to do so could result in the panel falling and causing serious injury.</P>
								</Admonition>
								<Figure src="/images/topic/G000145-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Open the BCU maintenance cover.</P>
							</Step>
						</Procedure>
						<Procedure title="INSTALL">
							<Step>
								<P>Close the BCU maintenance cover.</P>
								<Figure src="/images/topic/G000145-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Use a BCU access key (T1) to lock the 6 fasteners.</P>
							</Step>
							<Step>
								<IncludedTopic title="Side Skirts" subtitle="Remove and Install" toggleComingSoon={toggleComingSoon} />
							</Step>
						</Procedure>
					</>
				);
			case "D376707":
				return (
					<>
						{embed === false && (
							<PageHeading title="CID01A CARD" subtitle="REMOVE AND INSTALL" />
						)}
						<Procedure title="REMOVE">
							<Step>
								<IncludedTopic topicId="D019938" />
							</Step>
							<Step>
								<P>Disconnect the Ethernet connector.</P>
								<Figure src="/images/topic/G000146-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Record the position of the 2 RS485 connectors to aid with the installation process.</P>
								<Figure src="/images/topic/G000147-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Fully loosen the 4 fasteners and disconnect the 2 RS485 connectors.</P>
								<Figure src="/images/topic/G000147-01_pt2-thumb.jpg" />
							</Step>
							<Step>
								<P>Fully loosen the 4 fasteners.</P>
								<Figure src="/images/topic/G000148-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Remove the CID01A card.</P>
								<Figure src="/images/topic/G000149-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Push down and release the SD card to unlock it. Remove and keep the SD card.</P>
								<Spacer height="15px" />
								<YouTube videoId="8kqfuHizTjk" onReady={pauseVideo} opts={{ width: '400px', height: '230px', playerVars: { modestbranding: 1, rel: 0, listType: "playlist", end: 12 } }} />
								<Admonition type="note">
									<P>If there is no SD card installed, tell your brake system support engineer and create a defect notification in SAP to get a new SD card.</P>
								</Admonition>
							</Step>
						</Procedure>
						<Procedure title="INSTALL">
							<Step>
								<P>Examine the new CID01A card to see if an SD card is installed.</P>
								<Figure src="/images/topic/G000150-01-thumb.jpg" />
								<Admonition type="note">If there is no SD card installed, install the old SD card. Put the SD card in the holder and push it down to lock it in the correct position.
								</Admonition>
								<Figure src="/images/topic/G000151-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Install the CID01A card until it is flush with the card rack.</P>
							</Step>
							<Step>
								<P>Tighten the 4 fasteners.</P>
								<Figure src="/images/topic/G000148-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Install the 2 RS485 connectors in the positions recorded in the removal procedure.</P>
								<Figure src="/images/topic/G000147-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Tighten the 4 fasteners.</P>
								<Figure src="/images/topic/G000147-01_pt2-thumb.jpg" />
							</Step>
							<Step>
								<P>Connect the Ethernet connector.</P>
								<Figure src="/images/topic/G000146-01-thumb.jpg" />
							</Step>
							<Step>
								<IncludedTopic topicId="D376708" />
							</Step>
							<Step>
								<IncludedTopic topicId="D019938" />
							</Step>
						</Procedure>
					</>
				);
			case "D376708":
				return (
					<>
						{embed === false && (
							<PageHeading title="CID01A CARD - SOFTWARE" subtitle="CHECK AND UPDATE" />
						)}
						<Procedure title="CHECK">
							<Step>
								<P>Open the KB software database.</P>
								<Figure src="/images/topic/G000157-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Select the unit configuration (<b>1</b>) and the motor vehicle where the CID01A card is installed (<b>2</b>).</P>
							</Step>
							<Step>
								<P>Select <b>CID 0</b> (<b>1</b>).</P>
								<Figure src="/images/topic/G000158-01-thumb.jpg" />
							</Step>
							<Step>
								<P>Read the <b>Control, Proc. Configuration Ver...</b> (<b>2</b>) to see the software version.</P>
							</Step>
						</Procedure>
						<Procedure title="UPDATE">
							<Step>
								<P>Remove the SC4.</P>
								<Admonition type="warning">
									<P>The satefy conditions can change.</P>
									<P>Risk of death or serious injury.</P>
									<P>Use caution when you do this procedure.</P>
								</Admonition>
							</Step>
							<Step>
								<P>Power up the unit in accordance with RAH008.</P>
							</Step>
							<Step>
								<P>Use a laptop with KB interface tool and an M12 to RJ45 Cable [ST1] to connect to the brake system. Select the CID01A card and record the installed software version.</P>
								<Admonition type="note">

									<P>If the installed software version is different from the required software version:</P>
									<BulletList>
										<li>Tell your team leader.</li>
										<li>Raise a defect notification in SAP to install the required software version on the CID01A card.</li>
									</BulletList>
								</Admonition>
							</Step>
							<Step>
								<P>Disconnect the laptop from the brake system at the M12 to RJ45 Cable [ST1].</P>
							</Step>
						</Procedure>
					</>
				);
			case "D191220":
				return (
					<>
						<PageHeading title="CID01A CARD - SD CARD REMOVAL" subtitle="INSTRUCTIONAL VIDEO" />
						<Spacer height="20px" block={true} />
						<YouTube videoId="8kqfuHizTjk" onReady={pauseVideo} opts={{ width: '1000px', height: '562px', }} />
						<Admonition type="caution">Wear static-free gloves to prevent damage to electronic components</Admonition>
					</>
				);
			case "D120186":
				return (
					<>
						{embed === false && (
							<PageHeading title="GEARBOX OIL FILTER" subtitle="REMOVE AND INSTALL" />
						)}
						<Procedure title="REMOVE">
							<Step>
								<P>Remove the screws (x2) securing the access panel to the body.</P>
							</Step>
							<Step>
								<IncludedTopic topicId="D010162" />
							</Step>
							<Step>
								<P>Remove the oil filter contamination switch (<b>1</b>).</P>
								<Figure src="/images/topic/SI000001-thumb.png" />
							</Step>
							<Step>
								<P>Unscrew the oil filter from the gearbox housing.</P>
							</Step>
							<Step>
								<P>Remove and discard the oil filter gasket.</P>
								<Admonition type="danger">
									<P>Scalding can occur if hot gearbox oil comes into contact with the skin. When draining gearbox oil or changing the oil filter, always wear suitable protective gloves. Make sure that no gearbox oil gets inside the gloves.</P>
								</Admonition>
							</Step>
						</Procedure>
						<Procedure title="INSTALL">
							<Step>
								<P>Install the oil filter gasket.</P>
							</Step>
							<Step>
								<P>Install the oil filter to the gearbox housing.</P>
							</Step>
							<Step>
								<P>Install the oil filter contamination switch.</P>
								<Figure src="/images/topic/SI000001-thumb.png" />
							</Step>
							<Step>
								<IncludedTopic topicId="D010162" />
							</Step>
							<Step>
								<P>Install the access panel. Install the screws securing the access panel to the body.</P>
							</Step>
						</Procedure>
					</>
				)
			case 'D010162':
				return (
					<>
						{embed === false && (
							<PageHeading title="GEARBOX OIL" subtitle="DRAIN AND REFILL" />
						)}
						<Procedure title="DRAIN">
							<Step>
								<P>Remove the oil drain plug and discard the O-ring.</P>
								<Figure src="/images/topic/SI000003-thumb.png" />
								<Admonition type="caution">
									<P>Ensure a suitable container is placed beneath the drain plug, before it is removed, to collect any oil.</P>
								</Admonition>
							</Step>
							<Step>
								<P>Attach a vacuum drain system and remove the oil from the gearbox.</P>
							</Step>
							<Step>
								<P>Remove the vacuum drain system.</P>
							</Step>
						</Procedure>
						<Procedure title="REFILL">
							<Step>
								<P>Fill the gearbox until the oil level is in the center of the sight glass.</P>
								<Figure src="/images/topic/SI000004-thumb.png" />
							</Step>
							<Step>
								<P>Install the new O-ring on the oil drain plug.</P>
								<Figure src="/images/topic/SI000003-thumb.png" />
							</Step>
							<Step>
								<P>Lubricate the O-ring with new oil.</P>
							</Step>
							<Step>
								<P>Install the oil drain plug.</P>
							</Step>
						</Procedure>
					</>
				);
			case "D020662":
				return (
					<>
						<PageHeading title="GEARBOX MAINTENANCE" />
						<Admonition type="danger">
							<P>Failure to <ARoute to="/trn/course/T019938">shut down and lock out</ARoute> a machine, prior to routine maintenance, may result in death or serious injury.</P>
						</Admonition>
						<P>The gearbox must be maintained regularly to ensure operating efficiency.</P>
					</>
				);
			case "D240987":
				return (
					<>
						<PageHeading title="FLUID SPECIFICATIONS" subtitle="TECHNICAL SPECIFICATIONS" />
						<Table>
							<thead>
								<tr>
									<th>Component</th>
									<th>Specification</th>
									<th>Volume</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Metering drum drive-shaft bearing</td>
									<td>Grade 2 Lithium-based grease</td>
									<td>500ml</td>
								</tr>
								<tr>
									<td>Blower shaft bearings</td>
									<td>Grade 2 Lithium-based grease</td>
									<td>100ml</td>
								</tr>
								<tr>
									<td>Blower motor bearings</td>
									<td>Grade 2 Lithium-based grease</td>
									<td>400ml</td>
								</tr>
								<tr>
									<td>Metering drum gearbox</td>
									<td>ISO VG-220 mineral-based oil. AGMA Viscosity Grade 5EP. (MobilGear 630 or equivalent).</td>
									<td>12 litres</td>
								</tr>
							</tbody>
						</Table>
					</>
				)
			case 'T019938':
				return (
					<>
						<PageHeading title="LOCK OUT AND TAG OUT" subtitle="eLEARNING" />
						<P>Watch the video carefully and prepare to answer questions about Lock Out and Tag Out. </P>
						<Spacer height="20px" block={true} />
						<YouTube videoId="QM1PUNJFq6c" onReady={pauseVideo} opts={{ width: '1000px', height: '562px' }} />
						<P>When the video has finished, press <b>START</b> in the sidebar to start the quiz.</P>
					</>
				);
			case 'T011040':
				var debugMode = false;
				return (
					<>
						{embed === false && (
							<PageHeading title="A8A CAB CONTROL UNIT" subtitle="SCHEMATIC VIEWER" />
						)}
						<ComingSoon visible={comingSoonVisible} onCloseClick={toggleComingSoon} />
						<TransformWrapperHelper>
							<TransformWrapper initialScale={1}>
								<TransformComponent>
									<ImageMap width="100%" height="100%">
										<ImageMapItem hoverTitle="A8A Cab Control Module" id="A8A_hotspot" xPos="9.5%" yPos="46%" width="4%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D191220A') }} /> {/* Added */}
										<ImageMapItem hoverTitle="X3 Connector" id="X3_hotspot" xPos="50%" yPos="74%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D019940A') }} />
										<ImageMapItem hoverTitle="X1 Connector" id="X1_hotspot" xPos="38%" yPos="74%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D019939A') }} />
										<ImageMapItem hoverTitle="Fuse F131 - 15A" id="Fuse15A_hotspot" xPos="35%" yPos="80%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D019942A') }} />
										<ImageMapItem hoverTitle="Fuse F117 - 10A" id="Fuse10A_hotspot" xPos="47%" yPos="80%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D019941A') }} />
										<ImageMapItem hoverTitle="J100-2 Splice" id="SpliceJ100-2_hotspot" xPos="37%" yPos="62%" width="3%" height="5%" debug={debugMode} onClick={() => { navigate('/si/D019986A') }} />
										<ImageMapItem hoverTitle="J168-1 Splice" id="SpliceJ68-1_hotspot" xPos="48.5%" yPos="63%" width="3%" height="5%" debug={debugMode} onClick={toggleComingSoon} />
										<ImageMapItem hoverTitle="A8A2 Connector" id="A8A2_hotspot" xPos="11.5%" yPos="35.5%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D120187A') }} />
										<ImageMapItem hoverTitle="A8A2 Connector" id="A8A2_hotspot2" xPos="15%" yPos="49.5%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D120187A') }} />
										<ImageMapItem hoverTitle="A8A1 Connector" id="A8A1_hotspot" xPos="68%" yPos="36%" width="3%" height="4%" debug={debugMode} onClick={() => { navigate('/si/D120186A') }} />
										<SchematicT120186 />
									</ImageMap>
								</TransformComponent>
							</TransformWrapper>
						</TransformWrapperHelper>
					</>);
			case "D191220A":
				return (
					<>
						<PageHeading title="PRIMARY POWER CONTROL" subtitle="DESCRIPTION AND OPERATION" />
						<Figure src="/images/topic/POC0110230801-(PW)-thumb.jpg">
							<Legend>
								<LegendItem anno="1">Lamp switch</LegendItem>
								<LegendItem anno="2">Hazard switch</LegendItem>
								<LegendItem anno="3">Ignition switch</LegendItem>
								<LegendItem anno="4">ParkLock reverse pressure solenoid valve</LegendItem>
								<LegendItem anno="5">ParkLock disengage solenoid valve</LegendItem>
								<LegendItem anno="6">Engine control unit</LegendItem>
								<LegendItem anno="7">Transmission ECU</LegendItem>
								<LegendItem anno="8"><ARoute to="/si/D019938">Cab Control Unit</ARoute></LegendItem>
								<LegendItem anno="9">Wheel speed sensor</LegendItem>
								<LegendItem anno="10">Battery</LegendItem>
								<LegendItem anno="11">Connector, engine to cab</LegendItem>
								<LegendItem anno="12">Emergency button</LegendItem>
								<LegendItem anno="13">Relay, ACC, main switch</LegendItem>
								<LegendItem anno="14">Relay, main switch safety isolation</LegendItem>
							</Legend>
						</Figure>
						<P>The main switch is automatically set to ON when the ignition switch is in the ACC position. The ignition switch circuit energizes when the ignition switch is in the ON position.</P>
						<P>When the ignition switch is in the OFF position, the CAB ECU keeps the ignition switch circuit ON until the machine is stationary.</P>
						<P>The ParkLock solenoid energizes automatically when the machine speed is below 0.3 km/h.</P>
						<P>When the ignition switch is in the OFF position, the CAB ECU controls the period of time for the circuit to set to OFF.</P>
						<P>During this period of time:</P>
						<BulletList>
							<li><P>The machine stores its data.</P></li>
							<li><P>The system does a controlled electrical shutdown.</P></li>
							<li><P>The Diesel Exhaust Fluid (DEF) system pipes are emptied.</P></li>
						</BulletList>
						<P>For other machine functions, more time can be necessary, for example the ISOBUS or the Auto-Guide™ system. This time period can not be more than 1 hour.</P>
						<P>The main electrical power is not automatically set to OFF if:</P>
						<BulletList>
							<li><P>The marker lights are ON.</P></li>
							<li><P>The hazard switch is ON.</P></li>
						</BulletList>
						<H3>THE EMERGENCY BUTTON</H3>
						<P>In an emergency, all electrical power can be set to OFF immediately by the emergency button (12). The emergency button is in the console on the right side of the cab. The ignition switch must be in the OFF position, if not the relay (14) stops the emergency shutdown procedure.</P>
						<P>The emergency button has a green and a red LED lamp to show its status.</P>
						<P>To set the main power OFF, press and hold the red button for 3 seconds. The red lamp illuminates to show that the power is OFF.</P>
						<P>To reset the main power after an emergency shutdown or a battery cable is disconnected, press the green button.</P>
						<Admonition type="note">
							<P>The machine electrical system can not do a controlled shutdown, save data or drain the Diesel Exhaust Fluid (DEF) pipes when:</P>
							<BulletList>
								<li><P>No lamp illuminates, the emergency button condition is OFF.</P></li>
								<li><P>The green lamp illuminates, the emergency button status is ON.</P></li>
								<li><P>The green lamp flashes, the main power is shutting down.</P></li>
								<li><P>The red lamp flashes, the red button is pressed while the ignition is in the OFF position and the main power shuts down.</P></li>
								<li><P>The red lamp illuminates, the red button is pressed when the machine is in drive and the main power shuts down after the ignition is in the OFF position.</P></li>
							</BulletList>
						</Admonition>
					</>

				);
			case "D019938A":
				return (
					<>
						{embed === false && (
							<PageHeading title="A8A CAB CONTROL UNIT" subtitle="COMPONENT LOCATION" />
						)}
						<Figure src="/images/topic/POC538_7-(PW)-thumb.jpg" />
					</>
				);
			case "D019939A":
				return (
					<>
						{embed === false && (
							<PageHeading title="X1 CONNECTOR" subtitle="COMPONENT LOCATION" />
						)}
						<Figure src="/images/topic/POC538_3-(PW)-thumb.jpg" />
					</>
				);
			case "D019986A":
				return (
					<>
						{embed === false && (
							<PageHeading title="J100-2 SPLICE" subtitle="COMPONENT LOCATION" />
						)}
						<Figure src="/images/topic/J100-2-Splice-thumb.jpg" />
					</>
				);
			case "D019940A":
				return (
					<>
						{embed === false && (
							<PageHeading title="X3 CONNECTOR" subtitle="COMPONENT LOCATION" />
						)}
						<Figure src="/images/topic/POC538_4-(PW)-thumb.jpg" />
					</>
				);
			case "D019941A":
				return (
					<>
						{embed === false && (
							<PageHeading title="FUSE F117 - 10A" subtitle="COMPONENT LOCATION" />
						)}
						<Figure src="/images/topic/POC538_1-(PW)-thumb.jpg" />
					</>
				);
			case "D019942A":
				return (
					<>
						{embed === false && (
							<PageHeading title="FUSE F131 - 15A" subtitle="COMPONENT LOCATION" />
						)}
						<Figure src="/images/topic/POC538_2-(PW)-thumb.jpg" />
					</>
				);
			case "D120187A":
				return (
					<>
						{embed === false && (
							<PageHeading title="A8A2 CONNECTOR" subtitle="CONNECTOR REFERENCE" />
						)}
						<Figure src="/images/topic/POC538_5-(PW)-thumb.jpg" />
						<Table>
							<thead>
								<tr>
									<th>Pin number</th>
									<th>Group</th>
									<th>Component connection</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
								</tr>
								<tr>
									<td>2</td>
									<td>HS PWM output 1 A</td>
									<td>X43T</td>
									<td>Solenoid valve ON/OFF 2B</td>
								</tr>
								<tr>
									<td>3</td>
									<td>HS PWM output 1 A</td>
									<td>X44T</td>
									<td>Solenoid valve ON/OFF 2A</td>
								</tr>
								<tr>
									<td>4</td>
									<td>HS PWM output 3 A</td>
									<td>X892T</td>
									<td>PTO STD solenoid valve</td>
								</tr>
								<tr>
									<td>5</td>
									<td>HS PWM output 1 A</td>
									<td>-</td>
									<td>-</td>
								</tr>
								<tr>
									<td>6</td>
									<td>HS PWM output 1 A</td>
									<td>K1l</td>
									<td>ISOBUS ECU power relay</td>
								</tr>
								<tr>
									<td>7</td>
									<td>HS PWM output 3 A</td>
									<td>K13R</td>
									<td>Front left work lamps relay</td>
								</tr>
								<tr>
									<td>20</td>
									<td>Analog input</td>
									<td>X1068T</td>
									<td>Left side brake pedal pressure sensor</td>
								</tr>
								<tr>
									<td>33</td>
									<td>Frequence input</td>
									<td>X15T</td>
									<td>PTO clutch speed sensor</td>
								</tr>
								<tr>
									<td>40</td>
									<td>Current input</td>
									<td>-</td>
									<td>-</td>
								</tr>
								<tr>
									<td>45</td>
									<td>Sensor supply</td>
									<td>-</td>
									<td>Signal ground 2</td>
								</tr>
								<tr>
									<td>46</td>
									<td>Sensor supply</td>
									<td>-</td>
									<td>Signal ground 1</td>
								</tr>
								<tr>
									<td>58</td>
									<td>Digital input</td>
									<td>S124L</td>
									<td>Mudguard 10% switch panel</td>
								</tr>
							</tbody>
						</Table>
					</>
				);
			case "D120186A":
				return (
					<>
						{embed === false && (
							<PageHeading title="A8A1 CONNECTOR" subtitle="CONNECTOR REFERENCE" />
						)}
						<Figure src="/images/topic/POC538_6-(PW)-thumb.jpg" />
						<Table>
							<thead>
								<tr>
									<th>Pin number</th>
									<th>Group</th>
									<th>Component connection</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>15</td>
									<td>HS PWM output 3 A</td>
									<td>X654</td>
									<td>Hydraulic safety proportional valve</td>
								</tr>
								<tr>
									<td>16</td>
									<td>Analog input</td>
									<td>X32T</td>
									<td>Rear left side draft linkage sensor</td>
								</tr>
								<tr>
									<td>22</td>
									<td>Power supply</td>
									<td>-</td>
									<td>Ground</td>
								</tr>
								<tr>
									<td>23</td>
									<td>Power supply</td>
									<td>-</td>
									<td>Ground</td>
								</tr>
								<tr>
									<td>24</td>
									<td>Power supply</td>
									<td>-</td>
									<td>Ground</td>
								</tr>
								<tr>
									<td>31</td>
									<td>Inhibitor inputs</td>
									<td>-</td>
									<td>-</td>
								</tr>
								<tr>
									<td>53</td>
									<td>HS PWM output 3 A</td>
									<td>K13</td>
									<td>U-Pilot controlled relay</td>
								</tr>
								<tr>
									<td>54</td>
									<td>HS PWM output 3 A</td>
									<td>X3T</td>
									<td>PTO ECO solenoid valve</td>
								</tr>
								<tr>
									<td>61</td>
									<td>Communication</td>
									<td>-</td>
									<td>CAN3 High</td>
								</tr>
								<tr>
									<td>62</td>
									<td>Communication</td>
									<td>-</td>
									<td>CAN2 LOW</td>
								</tr>
								<tr>
									<td>63</td>
									<td>Communication</td>
									<td>-</td>
									<td>CAN3 High</td>
								</tr>
								<tr>
									<td>64</td>
									<td>Current input</td>
									<td>-</td>
									<td>-</td>
								</tr>
							</tbody>
						</Table>
					</>
				);
			default:
				return (
					<PageHeading title="TOPIC DOES NOT EXIST" subtitle="SORRY!" />
				);
		}
	}

	return { getTopic }

}

export default useGetTopic;