import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom'
import { LinkStyles } from './A';
 
const AgcoBranding = css`
	font-family: ${({ theme }) => theme.fonts.robotoBold};
	color: ${({ theme }) => theme.colors.Primary};
`;

export default styled(Link)`
    ${LinkStyles}

	${({ brand }) => brand === "agco" && AgcoBranding}
`;