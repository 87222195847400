import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Checklist from '../components/Checklist';
import TopicContainer from '../components/layout/TopicContainer';
import PageHeading from '../components/PageHeading';
import DocTypeIcon from '../components/DocTypeIcon';
import Sidebar from '../components/Sidebar';
import SidebarPanel from '../components/SidebarPanel';
import SidebarPanelLink from '../components/SidebarPanelLink';
import Button from '../components/Button';
import Spacer from '../components/layout/Spacer';
import topics from '../data/topics';

import { ComingSoonContext, SelectorContext } from '../App';
import useSidebar from '../hooks/useSidebar';

const Maintenance = ({ onComplete, loadChecklist, items, checklist, resetChecklist, toggleItem }) => {

	const menuContext = useContext(SelectorContext);
	const { toggleComingSoon } = useContext(ComingSoonContext);
	const { getSidebar } = useSidebar();

	const navigate = useNavigate();
	
	const { id } = useParams();
	
	function HandleReset() {
		resetChecklist((id));
	}

	React.useEffect(() => {
		loadChecklist(id);
		menuContext.setBreadcrumb(topics.filter(topic => topic.id === id)[0].breadcrumb);
	}, [checklist]);

	if (!checklist)
			return null;

	return ( 
		<>
			<TopicContainer>
				<PageHeading title={checklist.title && checklist.title.toUpperCase()}
				subtitle={checklist.subtitle && checklist.subtitle.toUpperCase()} />
				<Checklist data={items} descriptionField="description" checkedField="checked" keyField="id" onClick={toggleItem} />
			</TopicContainer>
			<Sidebar>
				{ getSidebar(id) }
				<SidebarPanel title="CHECKLIST" />
						<Spacer height="15px" block={true} />
						<Button type="primary" onClick={ (e) => { onComplete(JSON.parse(localStorage.getItem("user")).username); navigate('/mcs'); } } block={true} disabled={ items.filter(item => item.checked === false).length > 0 ? "disabled" : null }>COMPLETE</Button>
						<Spacer height="5px" block={true} />
						<Button type="outline" onClick={ HandleReset } block={true}>RESET</Button>
				
		</Sidebar>
	</>
	 );
}
 
export default Maintenance;