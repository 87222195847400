import React from 'react';
import { useParams } from 'react-router-dom';
import TopicContainer from '../components/layout/TopicContainer';
import PageHeading from '../components/PageHeading';
import Quiz from '../components/Quiz';
import Sidebar from '../components/Sidebar';
import useQuiz from '../hooks/useQuiz';
import QuizResult from '../components/QuizResult';

const TrainingQuiz = () => {

	const { quizId, datetime } = useParams();
	const { quiz, advance, questionData, resetQuiz, onAnswerClick, answers, resultsShowing, mark } = useQuiz( quizId );

	React.useEffect(() => {
		resetQuiz();
	}, [datetime]);

	return ( 
		<>
			<TopicContainer>
				
				{!resultsShowing && (
					<>
						<PageHeading title={quiz.title && quiz.title} subtitle={quiz.subtitle && quiz.subtitle} />
						<Quiz question={questionData} advance={ advance } onAnswerClick={ onAnswerClick } />
					</>)}
				{resultsShowing && (
					<>
						<QuizResult mark={ mark } answers={ answers }></QuizResult>
					</>
					)}
				
			</TopicContainer>
			<Sidebar>
			</Sidebar>
		</>
	 );
}
 
export default TrainingQuiz;