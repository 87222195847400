import React, { Children } from 'react';
import styled from 'styled-components';

const MenuItem = styled.li`
	font-size: ${({ theme }) => theme.fontSizes.xs};
	margin-top: 10px;
	margin-left: 50px;
`;

const SelectorMenuItem = ({ type, children, onClick }) => {
	return ( 
		<MenuItem onClick={ onClick } type={ type }>{ children }</MenuItem>
	 );
}
 
export default SelectorMenuItem;