import React from 'react';
import styled from 'styled-components';

import ChecklistItem from './ChecklistItem';

const StyledChecklist = styled.div`
	margin-top: 30px;
`;

const Checklist = ({ onClick, data, descriptionField, checkedField, keyField }) => {
	return ( 
		<StyledChecklist>
			{data && data.map((item, index) => (
					<ChecklistItem onClick={onClick} index={index} checked={item[checkedField]} description={item[descriptionField]} key={item[keyField]} item={item} />
				))}
		</StyledChecklist>
	 );
}
 
export default Checklist;