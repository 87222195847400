import React from 'react';
import styled from 'styled-components';
import SidebarMyMachine from './SidebarMyMachine';

import SidebarPanel from './SidebarPanel';
import SidebarPanelRow from './SidebarInfoRow';

const Container = styled.div`
	width: 244px;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 65px;
`;

const Sidebar = ({ children }) => {
	return ( 
		<Container>
			<SidebarMyMachine />
			{ children }
		</Container>
	 );
}
 
export default Sidebar;