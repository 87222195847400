import React from 'react';

export default function useComingSoon() {

	const [comingSoonVisible, setComingSoonVisible] = React.useState(false);

	function toggleComingSoon() {
		setComingSoonVisible(!comingSoonVisible);
	}
	
	return { comingSoonVisible, toggleComingSoon }

}