import React from 'react';
import users from '../data/users';
import checklists from '../data/checklists';

export default function useMaintenance() {

	const [checklist, setChecklist] = React.useState({});
	const [history, setHistory] = React.useState([]);
	const [items, setItems] = React.useState([]);

	function loadHistory() {
		let his = (localStorage.getItem("history") && JSON.parse(localStorage.getItem("history"))) || [];
		setHistory(his);
	}
	
	function loadChecklist(checklistId) {
		const cl = checklists.filter(c => c.id.toLowerCase() === checklistId.toLowerCase())[0] || [];
		setChecklist(cl);
		setItems(cl.items);
	}

	function resetChecklist(id) {
		changeAll(false);
	}

	function changeAll(boolean) {
		let csItems = [...checklist.items];
		for (var i=0; i < csItems.length; i++) {
			csItems[i].checked = boolean;
		}
		setItems(csItems);
	}

	function getCompletedDate() {
		const date = new Date();
		return date.toLocaleString("en-GB", {
			day: "numeric",
			month: "short",
			year: "numeric",
			hour: "numeric",
			minute: "2-digit",
		});
	}

	function completeChecksheet(username) {
		let checksheet = {...checklist};
		let updatedHistory = [...history];
		checksheet.username = username;
		checksheet.date = getCompletedDate();
		updatedHistory.push(checksheet);
		localStorage.setItem("history", JSON.stringify(updatedHistory));
		setHistory(updatedHistory);
		resetChecklist(checksheet.id);
	}

	function toggleItem(index) {
		let checksheet = {...checklist};
		let csItems = [...checklist.items];
		csItems[index].checked = !csItems[index].checked;
		checksheet.items = items;
		localStorage.setItem("checklist", JSON.stringify(checksheet));
		setItems(csItems);
	}

	return {
		completeChecksheet, 
		loadChecklist, 
		checklist, 
		resetChecklist, 
		items, 
		toggleItem, 
		history, 
		loadHistory, 
		changeAll
	}

}