import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'styled-components';

const Container = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.brand === "gsi" ? theme.colors.Black : theme.colors.GreySuperLight};
    padding: 10px;
`;

const Logo = styled.img`
    display: block;
    margin: auto;
    width: 137px;
`;

const HeaderSmall = () => {

	const theme = useTheme();

    return ( 
        <Container>
            <Logo src={ theme.logo } />
        </Container>
     );
}
 
export default HeaderSmall;