const structure = [
	{
		id: "0",
		name: "General",
		shortdesc: "General information, checklists and other documents",
		items: [
			{
				id: "01",
				name: "Warranty",
				items: [],
			},
			{
				id: "02",
				name: "Bulletins",
				items: [],
			},
			{
				id: "03",
				name: "Maintenance Checklists",
				items: [
					{
						id: "031",
						name: "Pre-Season Checks",
						items: [
							{
								name: "Pre-Season Checklist",
								href: "/mcs/cl1",
								type: "checklist",
								app: 2,
							},
						]
					},
					{
						id: "032",
						name: "End of Season Checks",
						items: [
							{
								name: "End of Season Shut-Down",
								href: "/mcs/cl2",
								type: "checklist",
								app: 2,
							},
						]
					},
				],
			},
		],
	},
	{
		id: "1",
		name: "Motor Car",
		shortdesc: "Engine, motors, driver's compartment fixtures and fittings",
		items: [],
	},
	{
		id: "2",
		name: "Roof",
		shortdesc: "Roof panels, roof sections and roof fittings",
		items: [],
	},
	{
		id: "3",
		name: "Vestibule",
		shortdesc: "Door controls, emergency stop, lighting controls",
		items: [],
	},
	{
		id: "4",
		name: "Underframe",
		shortdesc: "Bogies, wheelsets, braking system components",
		items: [
			{
				id: "41",
				name: "Bogie Assembly",
				items: [],
			},
			{
				id: "42",
				name: "Battery and Control System",
				items: [
					{
						id: "421",
						name: "Complete Trailer Wheelset",
						items: [],
					},
					{
						id: "422",
						name: "Brake Control Unit",
						items: [
							{
								name: "Flat Unload Section",
								href: "/epc",
								type: "epc",
								app: 1,
							},
							{
								name: "Brake Control Unit - Access",
								href: "/si/D019938",
								type: "si",
								app: 0,
							},
							{
								name: "CID01A Card",
								href: "/si/D376707",
								type: "si",
								app: 0,
							},
							{
								name: "CID01A Card - Software",
								href: "/si/D376708",
								type: "si",
								app: 0,
							},
							{
								name: "CID01A Card - SD Card Removal",
								href: "/si/D191220",
								type: "video",
								app: 0,
							},
							{
								name: "A8A Cab Control Unit",
								href: "/si/T011040",
								type: "schematics",
								app: 0,
							}
						],
					},
					{
						id: "423",
						name: "Piping and Wiring",
						items: [],
					},
					{
						id: "424",
						name: "AWS/TPWS Device",
						items: [],
					},
				],
			},
			{
				id: "43",
				name: "De-Icing System",
				items: [],
			},
			{
				id: "44",
				name: "Waste Water Tank",
				items: [],
			},
		],
	},
	{
		id: "5",
		name: "Body End",
		shortdesc: "Catwalk braces, floors and guard rails",
		items: [],
	},
];

export default structure;