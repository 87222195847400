import React from 'react';

import { ComingSoonContext } from '../App';

import TopicValidator from '../components/TopicValidator';
import SidebarPanel from '../components/SidebarPanel';
import SidebarPanelLink from '../components/SidebarPanelLink';
import DocTypeIcon from '../components/DocTypeIcon';

const useSidebar = () => {

	const { toggleComingSoon } = React.useContext(ComingSoonContext);

	function getSidebar(topicId, embed = false) {

		switch (topicId) {
			case 'D376707':
				return (
					<>
						<TopicValidator app={1}>
							<SidebarPanel title="SERVICE PARTS">
								<SidebarPanelLink to="/epc"><DocTypeIcon src="/images/icons/icon-epc-salmon.svg" />Main Board</SidebarPanelLink>
							</SidebarPanel>
						</TopicValidator>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={3}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-salmon.svg" />Safety Conditions</SidebarPanelLink></TopicValidator>
						</SidebarPanel>      
						<SidebarPanel title="SPECIAL TOOLS">
							<SidebarPanelLink to={'#'} onClick={toggleComingSoon}><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />BCU Access Hex Key</SidebarPanelLink>
							<SidebarPanelLink to={'#'} onClick={toggleComingSoon}><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />Laptop w/ KB Interface</SidebarPanelLink>
							<SidebarPanelLink to={'#'} onClick={toggleComingSoon}><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />M12 to RJ45 Cable</SidebarPanelLink>	
						</SidebarPanel>      
					</>
				);
				case 'D376708':
					return (
						<>
							<SidebarPanel title="SPECIAL TOOLS">
								<SidebarPanelLink to={'#'} onClick={toggleComingSoon}><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />Laptop w/ KB Interface</SidebarPanelLink>
								<SidebarPanelLink to={'#'} onClick={toggleComingSoon}><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />M12 to RJ45 Cable</SidebarPanelLink>	
							</SidebarPanel>      
						</>
					);
			case 'D019938':
				return (
					<>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={3}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-salmon.svg" />Manual Handling</SidebarPanelLink></TopicValidator>
						</SidebarPanel>      
						<SidebarPanel title="SPECIAL TOOLS">
							<SidebarPanelLink to={'#'} onClick={toggleComingSoon}><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />BCU Access Hex Key</SidebarPanelLink>
						</SidebarPanel>          
					</>
				);
			case "D191220":
				return (
					<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/T011040"><DocTypeIcon src="/images/icons/icon-schematics-salmon.svg" />Brake Control Module</SidebarPanelLink></TopicValidator>
						</SidebarPanel>
				);
				case 'D020662':
				return (
					<>
						<TopicValidator app={1}>
							<SidebarPanel title="SERVICE PARTS">
								<SidebarPanelLink to="/epc"><DocTypeIcon src="/images/icons/icon-epc-salmon.svg" />Gearbox Oil - 5L</SidebarPanelLink>
							</SidebarPanel>
						</TopicValidator>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/D240987"><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />Fluid Specifications</SidebarPanelLink></TopicValidator>
							<TopicValidator app={3}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-salmon.svg" />Fluid Disposal Safety</SidebarPanelLink></TopicValidator>
						</SidebarPanel>            
					</>
				);
				case "cl1":
				return (
					<>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}>
							<SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-si-salmon.svg" />Fluid Specifications</SidebarPanelLink>
							</TopicValidator>
							<TopicValidator app={4}>
								<SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-salmon.svg" />Fluid Disposal Safety</SidebarPanelLink>
							</TopicValidator>
						</SidebarPanel>
					</>
				);
			case 'T011040':
				return (
					<>
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="#" onClick={ toggleComingSoon }><DocTypeIcon src="/images/icons/icon-training-salmon.svg" />Electrical Safety</SidebarPanelLink></TopicValidator>
						</SidebarPanel>            
					</>
				);
				case 'D120186A':
					return (
						<>
							<SidebarPanel title="RELATED TOPICS">
								<TopicValidator app={0}><SidebarPanelLink to="/si/D019938A"><DocTypeIcon src="/images/icons/icon-dando-salmon.svg" />A8A Cab Control Unit</SidebarPanelLink></TopicValidator>
							</SidebarPanel>            
						</>
					);
				case 'D120187A':
					return (
						<>
							<SidebarPanel title="RELATED TOPICS">
								<TopicValidator app={0}><SidebarPanelLink to="/si/D019938A"><DocTypeIcon src="/images/icons/icon-dando-salmon.svg" />A8A Cab Control Unit</SidebarPanelLink></TopicValidator>
							</SidebarPanel>            
						</>
					);
				case 'D019938A':
					return (
						<>
							<SidebarPanel title="RELATED TOPICS">
								<TopicValidator app={0}><SidebarPanelLink to="/si/D120186A"><DocTypeIcon src="/images/icons/icon-dando-salmon.svg" />A8A1 Connector</SidebarPanelLink></TopicValidator>
								<TopicValidator app={0}><SidebarPanelLink to="/si/D120187A"><DocTypeIcon src="/images/icons/icon-dando-salmon.svg" />A8A2 Connector</SidebarPanelLink></TopicValidator>
							</SidebarPanel>            
						</>
					);
				case "D191220A":
					return (
						<SidebarPanel title="RELATED TOPICS">
							<TopicValidator app={0}><SidebarPanelLink to="/si/T120186"><DocTypeIcon src="/images/icons/icon-conref-salmon.svg" />A8A Cab Control Unit</SidebarPanelLink></TopicValidator>
						</SidebarPanel>
					);
			default:
				return(
					<></>
				);
		}
	}

	return { getSidebar }

}
 
export default useSidebar;