import React from 'react';
import styled from 'styled-components';

const Item = styled.li`
	position: relative;
	padding-left: 20px;
	font-size: ${({ theme }) => theme.fontSizes.xs};
	margin-bottom: 10px;
	&:before {
		display: block;
		position: absolute;
		top: 2px;
		left: -20px;
		content: '${p => p.anno}';
		color: ${({ theme }) => theme.colors.Primary};
		font-weight: bold;
		font-size: ${({ theme }) => theme.fontSizes.xs};
	}
`;

const LegendItem = ({ children, anno }) => {
	return ( 
		<Item anno={anno}>{ children }</Item>
	 );
}
 
export default LegendItem;