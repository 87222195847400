import React from 'react';

import quizzes from '../data/quizzes';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

export default function useQuiz(id) {

	const navigate = useNavigate();
	const { setCourseComplete } = React.useContext(AuthContext);

	const [ quiz, updateQuiz ] = React.useState(quizzes.filter(quiz => quiz.id === id)[0] || null)
	const [ questions, setQuestions ] = React.useState(quizzes.filter(quiz => quiz.id === id)[0].questions || null);
	const [ currentQuestion, setCurrentQuestion ] = React.useState(1);
	const [ questionData, setQuestionData ] = React.useState({...quizzes.filter(quiz => quiz.id === id)[0].questions[0]});
	const [ answers, setAnswers ] = React.useState([]);
	const [ resultsShowing, setResultsShowing ] = React.useState(false);
	const [ mark, setMark ] = React.useState(0);

	if (!quizzes.filter(quiz => quiz.id === id)[0]) {
		console.warn("Quiz does not exist");
		return;
	}

	function advance() {
		let ans = [...answers];
		ans[currentQuestion - 1] = questionData.answers.filter(answer => answer.checked)[0];
		setAnswers(ans);
		if (currentQuestion >= questions.length) {
			setResultsShowing(true);
			let correctAnswers = ans.filter(answer => answer.checked && answer.correct);
			setMark(Math.round((correctAnswers.length / ans.length) * 100));
			if ((Math.round((correctAnswers.length / ans.length) * 100)) > 75) {
				setCourseComplete(true);
			}
			return;
		}

		let nextQuestion = questions[currentQuestion];
		setQuestionData(nextQuestion);
		setCurrentQuestion(currentQuestion + 1);
	}

	function onAnswerClick(answerId) {
		let qd = {...questionData};
		let qs = [...questions];
		for (var i = 0; i < qd.answers.length; i++) {
			if (qd.answers[i].id === answerId) {
				qd.answers[i].checked = !qd.answers[i].checked;
			}
			else
			{
				qd.answers[i].checked = false;
			}
		}
		qs[currentQuestion - 1] = qd;
		setQuestionData(qd);
		setQuestions(qs);

	}

	function resetQuiz() {
		if (!resultsShowing) {
			updateQuiz(quizzes.filter(quiz => quiz.id === id)[0] || null);
			setQuestions(quizzes.filter(quiz => quiz.id === id)[0].questions || null);
			setCurrentQuestion(1);
			setQuestionData({...quizzes.filter(quiz => quiz.id === id)[0].questions[0]});
		}
		console.log("Resetting the quiz now");
	}

	return { 
		quiz, 
		questions, 
		currentQuestion, 
		questionData,
		advance, 
		onAnswerClick,
		resetQuiz,
		answers,
		resultsShowing,
		mark
	 }

}