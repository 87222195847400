import React, { useContext } from 'react';
import { AuthContext } from '../App';

const TopicValidator = ({ children, app }) => {

	const { authenticatedUser } = useContext(AuthContext);

	if (!(authenticatedUser && authenticatedUser.apps.includes(app)))
		return null;

	return ( 
		<>
			{ children }
		</>
	 );
}
 
export default TopicValidator;