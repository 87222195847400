import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Item = styled.div`
	width: 100%;
	border-bottom: solid 1px ${({ theme }) => theme.colors.GreyLight};
	padding: 15px 15px 20px 15px;
	cursor: pointer;
`;

const ItemTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.xxl};
`;

const Icon = styled.img`
	display: inline;
	margin-top: -2px;
	margin-right: 10px;
`;

const ItemSubtitle = styled.div`
	color: ${({ theme }) => theme.colors.GreyMid};
	font-size: ${({ theme }) => theme.fontSizes.xs};
	font-family: ${({ theme }) => theme.fonts.regular};
	margin-top: 5px;
`;

const SearchItem = ({ title, subtitle, icon, href, toggleModal }) => {

	const navigate = useNavigate();

	function handleClick(to) {
		navigate(to);
		toggleModal();
	}

	return ( 
		<Item onClick={() => {handleClick(href)}}>
			<ItemTitle>{ title }</ItemTitle>
			<ItemSubtitle><Icon src={`/images/icons/icon-${ icon }-greylight.svg`} />{ subtitle }</ItemSubtitle>
		</Item>
	 );
}
 
export default SearchItem;