import React from 'react';
import styled, { css } from 'styled-components';

const SemconBranding = css`
	background-color: ${({ theme }) => theme.colors.GreySuperLight};
	border-bottom: solid 3px ${({ theme }) => theme.colors.GreyDark};
	font-family: ${({ theme }) => theme.fonts.slabRegular};
	padding: 10px;

	&:focus {
		border-bottom: solid 3px ${({ theme }) => theme.colors.Primary};
	}
`;

export default styled.input`
	${({ block }) => block && block==true ? "display: block;" : "display: inline-block;"}
	${({ width }) => width ? `width: ${width};` : ""}
	${({ brand }) => brand === 'semcon' && SemconBranding}
	transition: all 0.5s ease;
`;