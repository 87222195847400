import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from './Button';
import PageHeading from './PageHeading';
import Spacer from './layout/Spacer';

const Result = styled.div`
	margin-top: 250px;
	margin-left: 150px;
`;

const QuizResult = ({ mark }) => {

	const navigate = useNavigate();
	console.log(window.location.pathname);

	return ( 
		<Result>
			<PageHeading title={mark >= 75 ? "You passed! Nice one!" : "You failed. Try again!"} subtitle={`You scored ${ mark }%`} />
				<Spacer height="30px" block={true} />
				<Button type="primary" onClick={() => {mark >= 75 ? navigate("/trn") : navigate(window.location.pathname.replace("/quiz", "/course"))}}>
					{mark >= 75 ? "Close Course" : "Restart Course"}
					</Button>
		</Result>
	 );
}
 
export default QuizResult;