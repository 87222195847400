import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex-box;
    width: 1246px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 50px;
`;

const TopicContainer = ({ children }) => {
    return ( 
        <Container>
            { children }
        </Container>
     );
}
 
export default TopicContainer;