import React from 'react';
import styled from 'styled-components';

const MapWrapper = styled.div`
	width: ${({ width }) => width ? width : '100%'};
	height: ${({ height }) => height ? height : '100%'};

	g[id^='wires']:hover {
		& line {
			cursor: pointer;
			stroke: cyan;
		}
	}
`;

const ImageMap = ({ children, height, width}) => {
	return ( 
		<MapWrapper height={height} width={width}>
			{ children }
		</MapWrapper>
	 );
}
 
export default ImageMap;