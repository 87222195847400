const topics = [
	{
		id: "D019938",
		title: "Brake Control Unit - Access",
		subtitle: "Remove and Install",
		href: "/si/D019938",
		app: 0,
		icon: "si",
		keywords: ["accessing", "brakes", "controls", "braking"],
		breadcrumb: [ 
				{name: "Underframe", href: "/sel/4", id: "4"},
				{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
				{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
				{name: "Brake Control Unit - Access", href: null, id: null}
			 ]
	},
	{
		id: "D376707",
		title: "CID01A Card",
		subtitle: "Remove and Install",
		href: "/si/D376707",
		app: 0,
		icon: "si",
		keywords: [],
		breadcrumb: [
				{name: "Underframe", href: "/sel/4", id: "4"},
				{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
				{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
				{name: "CID01A Card", href: null, id: null}
		]
	},
	{
		id: "D376708",
		title: "CID01A Card - Software",
		subtitle: "Check and Update",
		href: "/si/D376708",
		app: 0,
		icon: "si",
		keywords: [],
		breadcrumb: [
				{name: "Underframe", href: "/sel/4", id: "4"},
				{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
				{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
				{name: "CID01A Card - Software", href: null, id: null}
		]
	},
	{
		id: "trainingdash",
		title: "Training Dashboard",
		subtitle: "Training",
		href: "/trn",
		app: 3,
		icon: "learning",
		keywords: ["dash", "board", "learning"],
		breadcrumb: [ 
				{name: "Dashboard", href: "/trn", id: null},
				{name: "Lock Out and Tag Out", href: "/trn/course/T019938", id: null}
			 ]
	},
	{
		id: "T019938",
		title: "Lock Out and Tag Out",
		subtitle: "eLearning",
		href: "/si/T019938",
		app: 3,
		icon: "learning",
		keywords: ["safety", "locks", "locking", "tags", "tagging"],
		breadcrumb: [ 
				{name: "Dashboard", href: "/trn", id: null},
				{name: "Lock Out and Tag Out", href: "/trn/course/T019938", id: null}
			 ]
	},
	{
		id: "T011040",
		title: "A8A Cab Control Unit",
		subtitle: "Schematic Viewer",
		href: "/si/T011040",
		app: 0,
		icon: "schematics",
		keywords: ["schematic", "diagram", "modules", "module", ],
		breadcrumb: [ 
				{name: "Underframe", href: "/sel/4", id: "4"},
				{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
				{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
				{name: "A8A Cab Control Unit", href: null, id: null}
			 ]
	},
	{
		id: "D191220",
		title: "CID01A Card - SD Card Removal",
		subtitle: "Video",
		href: "/si/D191220",
		app: 0,
		icon: "video",
		keywords: ["SD", "electronic", "cards", "chip", "circuit", "board" ],
		breadcrumb: [ 
				{name: "Underframe", href: "/sel/4", id: "4"},
				{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
				{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
				{name: "CID01A Card - SD Card Removal", href: null, id: null}
			 ]
	},
	{
		id: "D150218",
		title: "Flat Unload Section",
		subtitle: "Parts Catalogue",
		href: "/epc",
		app: 1,
		icon: "epc",
		keywords: ["parts", "gearbox", "oil", "epc"],
		breadcrumb: [ 
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{name: "Flat Unload Section", href: null, id: null}
		 ]
	},
	{
		id: "maintenancedash",
		title: "Maintenance Dashboard",
		subtitle: "Maintenance",
		href: "/mcs",
		app: 2,
		icon: "checklist",
		keywords: ["maintenance", "dashboard", "history"],
		breadcrumb: [ 
			{name: "Dashboard", href: "/mcs", id: null}
		 ],
		 searchable: false,
	},
	{
		id: "cl1",
		title: "Pre-Season Service",
		subtitle: "Maintenance Checklist",
		href: "/mcs/cl1",
		app: 2,
		icon: "checklist",
		keywords: ["maintenance", "before", "preceding", "checksheet", "list", "checklist"],
		breadcrumb: [ 
			{name: "General", href: "/sel/0", id: "0"},
			{name: "Maintenance Checklists", href: "/sel/0/3", id: "03"},
			{name: "Pre-Season Checks", href: "/sel/0/3/1", id: "031"},
			{name: "Pre-Season Checklist", href: null, id: null}
		 ]
	},
	{
		id: "cl2",
		title: "End of Season Shutdown",
		subtitle: "Maintenance Checklist",
		href: "/mcs/cl2",
		app: 2,
		icon: "checklist",
		keywords: ["maintenance", "after", "following", "checksheet", "list", "checklist"],
		breadcrumb: [ 
			{name: "General", href: "/sel/0", id: "0"},
			{name: "Maintenance Checklists", href: "/sel/0/3", id: "03"},
			{name: "End of Season Checks", href: "/sel/0/3/2", id: "032"},
			{name: "End of Season Shut-Down", href: null, id: null}
		 ]
	},
	{
		id: "sidash",
		title: "Service Information Dashboard",
		subtitle: "Service Information",
		href: "/si",
		app: 0,
		icon: "si",
		keywords: ["service", "information", "dashboard"],
		breadcrumb: [ 
			{name: "Dashboard", href: "/si", id: null}
		 ],
		 searchable: false
	},
	{
		id: "D191220A",
		title: "Primary Power Control",
		subtitle: "Description and Operation",
		href: "/si/D191220",
		app: 0,
		icon: "dando",
		keywords: ["controls", "primaries", "description", "operation", "controller",],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "Primary Power Control", href: null, id: null }
		]
	},
	{
		id: "D019938A",
		title: "A8A Cab Control Unit",
		subtitle: "Component Location",
		href: "/si/D019938A",
		app: 0,
		icon: "si",
		keywords: ["description", "operation", "module", "electrical", "position"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "A8A Cab Control Unit", href: null, id: null }
		]
	},
	{
		id: "D019939A",
		title: "X1 Connector",
		subtitle: "Component Location",
		href: "/si/D019939A",
		app: 0,
		icon: "si",
		keywords: ["description", "operation", "connector", "electrical", "position"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "X1 Connector", href: null, id: null }
		]
	},
	{
		id: "D019986A",
		title: "J68-1 Splice",
		subtitle: "Component Location",
		href: "/si/D019986A",
		app: 0,
		icon: "si",
		keywords: ["description", "operation", "splice", "electrical", "position"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "J68-1 Splice", href: null, id: null }
		]
	},
	{
		id: "D019940A",
		title: "X3 Connector",
		subtitle: "Component Location",
		href: "/si/D019940A",
		app: 0,
		icon: "si",
		keywords: ["description", "operation", "connector", "electrical", "position"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "X3 Connector", href: null, id: null }
		]
	},
	{
		id: "D019941A",
		title: "Fuse F117 - 10A",
		subtitle: "Component Location",
		href: "/si/D019941A",
		app: 0,
		icon: "si",
		keywords: ["description", "operation", "fuses", "electrical", "position"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "Fuse F117 - 10A", href: null, id: null }
		]
	},
	{
		id: "D019942A",
		title: "Fuse F131 - 15A",
		subtitle: "Component Location",
		href: "/si/D019942A",
		app: 0,
		icon: "si",
		keywords: ["description", "operation", "fuses", "electrical", "position"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "Fuse F131 - 15A", href: null, id: null }
		]
	},{
		id: "D120187A",
		title: "A8A2 Connector",
		subtitle: "Connector Reference",
		href: "/si/D120187",
		app: 0,
		icon: "conref",
		keywords: ["connector", "reference", "connectors", "library"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "A8A1 Connector", href: null, id: null }
		]
	},
	{
		id: "D120186A",
		title: "A8A1 Connector",
		subtitle: "Connector Reference",
		href: "/si/D120186A",
		app: 0,
		icon: "conref",
		keywords: ["connector", "reference", "connectors", "library"],
		breadcrumb: [
			{name: "Underframe", href: "/sel/4", id: "4"},
			{name: "Battery and Control System", href: "/sel/4/2", id: "42"},
			{name: "Brake Control Unit", href: "/sel/4/2/2", id: "422"},
			{ name: "A8A1 Connector", href: null, id: null }
		]
	},
]

export default topics;