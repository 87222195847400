import React from 'react';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type : 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
	display: inline-block;
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: 25px;
	width: 25px;
	border: solid 2px ${({ checked }) => checked ? ({ theme }) => theme.colors.Primary : ({ theme }) => theme.colors.GreyMid}; 
	${({ theme }) => theme.colors.GreyMid};
	border-radius: ${({ theme }) => theme.radii.m};
	background-color: ${({ checked }) => checked ? ({ theme }) => theme.colors.Primary : "transparent"};
	${({ transitions }) => transitions ? "transition: all 0.5s;" : ""}
`;

const CheckIcon = styled.img`
	align-self: center;
	width: 15px;
	${({ transitions }) => transitions ? "transition: all 0.5s linear;" : ""}
	opacity: ${({ checked }) => !checked ? "0" : "1"};

`;

const Checkbox = ({ checked, onClick, transitions = true, ...props }) => {
	return ( 
		<>
			<HiddenCheckbox {...props} />
			<StyledCheckbox transitions={transitions} onClick={ onClick } checked={ checked }>
				<CheckIcon transitions={transitions} checked={ checked } src='/images/icons/icon-check-white.svg' />
			</StyledCheckbox> 
		</>
	);
}
 
export default Checkbox;