const checklists = [
	{
		id: "CL1",
		title: "Pre-Season Service",
		subtitle: "Maintenance Checklist",
		items: [
			{
				id: 1,
				checked: false,
				description: "Lubricate blower bearings.",
			},
			{
				id: 2,
				checked: false,
				description: "Lubricate blower motor bearings, if required.",
			},
			{
				id: 3,
				checked: false,
				description: "Check blower belts and adjust as necessary.",
			},
			{
				id: 4,
				checked: false,
				description: "Clean burner ports.",
			},
			{
				id: 5,
				checked: false,
				description: "Inspect flame rod and spark ignitor.",
			},
			{
				id: 6,
				checked: false,
				description: "Check brake control module access panel.",
				topicRef: "D019938",
			},
			{
				id: 7,
				checked: false,
				description: "Inspect divider hopper between heat and cooling section. Clean if necessary.",
			},
			{
				id: 8,
				checked: false,
				description: "Inspect Bindicator grain level switches.",
			},
			{
				id: 9,
				checked: false,
				description: "Inspect metering system access door cover seals.",
			},
			{
				id: 10,
				checked: false,
				description: "Inspect metering system access door cover hold down latches.",
			},
			{
				id: 11,
				checked: false,
				description: "Lube Modutrol motor linkage.",
			},
			{
				id: 12,
				checked: false,
				description: "Check butterfly operation in modulating valve.",
			},
			{
				id: 13,
				checked: false,
				description: "Check gas pressure gauges.",
			},
			{
				id: 14,
				checked: false,
				description: "Check interior of Maxon shutoff valves for corrosion. Clean if necessary.",
			},
			{
				id: 15,
				checked: false,
				description: "Clean control and power panels, tighten loose connections and check for leaks",
			},
			{
				id: 16,
				checked: false,
				description: "Inspect metering systems. Clean accumulated stalks and old grain.",
			},

		]
	},
	{
		id: "CL2",
		title: "End of Season Shut-Down",
		subtitle: "Maintenance Checklist",
		items: [
			{
				id: 1,
				checked: false,
				description: "Start unload and empty all grain from dryers."
			},
			{
				id: 2,
				checked: false,
				description: "Clean out grain cushion (on plenum roof under fill spout). Clean plenum roof."
			},
			{
				id: 3,
				checked: false,
				description: "Clean off grain exchangers."
			},
			{
				id: 4,
				checked: false,
				description: "Clean out divider hopper, between heating and cooling section."
			},
			{
				id: 5,
				checked: false,
				description: "Clean inside cooling sheets and cooling floor."
			},
			{
				id: 6,
				checked: false,
				description: "Remove all grain and trash from unload section of dryer."
			},
			{
				id: 7,
				checked: false,
				description: "Open emergency grain discharge doors (and drain doors in Zimmerman dryers)."
			},
			{
				id: 8,
				checked: false,
				description: "Open drain valve in gas train."
			},
			{
				id: 9,
				checked: false,
				description: "Cover burner with a tarp or plastic sheeting."
			},
		]
	}
]

export default checklists