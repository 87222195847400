import React from 'react';
import styled from 'styled-components';

const ProcedureStep = styled.li`
    padding: 0 10px 0px 52px;
    margin-top: 20px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const Step = ({ children }) => {
    return ( 
        <ProcedureStep>
            { children }
        </ProcedureStep>
     );
}
 
export default Step;