import React from 'react';
import styled from 'styled-components';

const WrapperHelper = styled.div`
	& >div {
		width: 100% !important;
		height: 100% !important;
		& >div {
			width: 100% !important;
			height: 100% !important;
		}
	}
`;

const TransformWrapperHelper = ({ children }) => {
	return ( 
		<WrapperHelper>
			{ children }
		</WrapperHelper>
	 );
}
 
export default TransformWrapperHelper;