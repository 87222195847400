import React from 'react';
import styled from 'styled-components';

const Row = styled.tr`
	border-bottom: none !important;
`;

const Label = styled.td`
	font-weight: ${({ theme }) => theme.fontWeights.bold};
	padding: ${({ theme }) => theme.space.sidebarRow} !important;
`;

const Text = styled.td`
	padding: ${({ theme }) => theme.space.sidebarRow} !important;
`;

const SidebarInfoRow = ({ label, text }) => {
	return ( 
		<Row>
			<Label>{ label }</Label>
			<Text>{ text }</Text>
		</Row>
	 );
}
 
export default SidebarInfoRow;