import React from 'react';

export default function useSearch() {

	const [searchVisible, setSearchVisible] = React.useState(false);

	function toggleSearch() {
		setSearchVisible(!searchVisible);
	}
	
	return { searchVisible, toggleSearch }

}