import React from 'react';
import styled from 'styled-components';

import TopicContainer from '../components/layout/TopicContainer';
import PageHeading from '../components/PageHeading';
import H3 from '../components/typography/H3';
import P from '../components/typography/P';

import Sidebar from '../components/Sidebar';
import SidebarPanel from '../components/SidebarPanel';
import SidebarInfoRow from '../components/SidebarInfoRow';
import { useNavigate } from 'react-router-dom';
import { AuthContext, SelectorContext } from '../App';
import { useContext } from 'react';

import topics from '../data/topics';

const CourseList = styled.div`
	width: 100%;
`;

const CourseListItem = styled.div`
	position: relative;
	display: inline-block;	
	margin-top: 30px;
	margin-right: 35px;
	cursor: pointer;
`;

const CourseTitle = styled.div`
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: ${({ theme }) => theme.colors.White};
	font-family: ${({ theme }) => theme.fonts.title};
	font-size: ${({ theme }) => theme.fontSizes.xs};
	font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const CompleteIcon = styled.img`
	position: absolute;
	top: 0px;
	right: 0px;
`;

const TrainingDash = () => {

	const menuContext = useContext(SelectorContext);
	const navigate = useNavigate();
	const { courseComplete } = React.useContext(AuthContext);
	const date = new Date();

	React.useEffect(() => {
		menuContext.setBreadcrumb(
			topics.filter(topic => topic.id === "maintenancedash")[0].breadcrumb
		)
	}, []);

	return ( 
		<>
			<TopicContainer>
				<PageHeading title="TRAINING" subtitle="DASHBOARD" />
				<H3>MY COURSES</H3>
				<CourseList>
					{!courseComplete && (
						<CourseListItem onClick={() => {navigate('/trn/course/T019938')}}>
							<img src="/images/training-lock-out-tag-out.png" />
							<CourseTitle>LOCK OUT AND TAG OUT</CourseTitle>
						</CourseListItem>
						)}
						{courseComplete && (
							<P>You have completed all the assigned courses.</P>
						)}
					
				</CourseList>
				<H3>RECENTLY COMPLETED</H3>
				<CourseList>
					{courseComplete && (
						<CourseListItem onClick={() => {navigate('/trn/course/T019938')}}>
							<img src="/images/training-lock-out-tag-out.png" />
							<CourseTitle>LOCK OUT AND TAG OUT</CourseTitle>
							<CompleteIcon src="/images/icons/icon-complete.svg" />
						</CourseListItem>
						)}
					<CourseListItem>
						<img src="/images/training-complete.png" />
						<CourseTitle>MANUAL HANDLING</CourseTitle>
					</CourseListItem>
					<CourseListItem>
						<img src="/images/training-complete.png" />
						<CourseTitle>SAFETY CONDITIONS</CourseTitle>
					</CourseListItem>
					<CourseListItem>
						<img src="/images/training-complete.png" />
						<CourseTitle>WORKSHOP PROCESSES</CourseTitle>
					</CourseListItem>
				</CourseList>
			</TopicContainer>
			<Sidebar>
				<SidebarPanel title="COURSE COMPLETION">
						<SidebarInfoRow label="Done:" text={`${courseComplete ? "4" : "3" } of 4`}/>
						<SidebarInfoRow label="Last:" text={courseComplete ? date.getDay(2) + " - " + date.getMonth(2) + " - " + date.getFullYear() : "12 - 01 - 2022" } />
					</SidebarPanel>
			</Sidebar>
		</>
	 );
}
 
export default TrainingDash;