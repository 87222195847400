import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { NavLink, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

import UserMenu from './UserMenu';

import apps from '../data/apps';
import SelectorBar from './SelectorBar';
import Search from './Search';
import { ComingSoonContext, SearchContext } from '../App';

const Bar = styled.div`
	background-color: ${({ theme }) => theme.colors.GreyDark};
	height: 51px;
	min-width: 100%;
`;

const Container = styled.div`
	position: relative;
	height: 100%;
`;

const AccessoriesMenu = styled.span`
	position: absolute;
	top: 12px;
	right: 10px;
`;

const MenuItem = styled.span`
	${({ visible }) => !visible && "display: none;"}
	margin-left: 20px;
	margin-right: 20px;
	color: ${({ theme }) => theme.brand === "gsi" ? theme.colors.White : theme.colors.Primary};
	cursor: pointer;
`;

const NavLinks = styled.span`
	${({ theme }) => theme.brand === "agco" ? "display: none;" : ""}
	position: absolute;
	top: 10px;
	left: 10px;	
`;

const LinkStyles = css`
	margin: 20px;
	font-family: ${({ theme }) => theme.fonts.title};
	text-transform: uppercase;
	color: ${({ theme }) => theme.brand === "gsi" ? theme.colors.White : theme.colors.Primary};
	font-size: ${({ theme }) => theme.fontSizes.l};
	padding-bottom: 3px;
	border-bottom: solid 3px ${({ theme }) => theme.colors.GreyDark};
	transition: all 0.5s ease;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.colors.Primary};
	}
`;

const NavigationLink = styled(NavLink)`
	${LinkStyles}

	&.active {
		border-bottom: solid 3px ${({ theme }) => theme.colors.Primary}
	}
`;

const DeadLink = styled.a`
	${LinkStyles}
`;

const Logo = styled.img`
	position: absolute;
	top: 67px;
	right: 0;
	display: inline;
	height: 41px;
`;

const LanguageSelect = styled.img`
	display: inline;
`;

const User = styled.a`
	& > img {
		display: inline;
	}
`;

const ShoppingCart = styled.div`
	position: relative;
	display: inline-block;
`

const ShoppingCartIcon = styled.img`
	display: ${({ theme }) => theme.brand === "gsi" ? 'inline' : 'none'};
`;

const ShoppingCartHasParts = styled.div`
	position: absolute;
	display: inline-block;
	height: 12px;
	width: 12px;
	background-color: ${({ theme }) => theme.colors.Primary};
	border: solid 1px ${({ theme }) => theme.colors.White};
	border-radius: 50%;
	right: 0px;
	opacity: ${({ visible }) => visible ? "1" : "0"};
	transition: all 0.5s ease;
`;

const SearchIcon = styled.img`
	display: ${({ theme }) => theme.brand === "gsi" ? 'inline' : 'none'};
`;

const Header = ({ logout, basket, selectorVisible = true }) => {
	  
	const navigate = useNavigate();
	const locationHref = window.location.pathname;
	const themeContext = useContext(ThemeContext);
	const { toggleComingSoon } = useContext(ComingSoonContext);
	const { searchVisible, toggleSearch } = useContext(SearchContext);
	console.log(locationHref.indexOf('/vehicle'));

	return ( 
		<>
			<Bar>
				<Container>
					{locationHref.indexOf('/vehicle') !== 0 && (
						<NavLinks>
							{ apps.map((app, index) => {
								return localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).apps.includes(index) ? 
								( app.comingsoon === true ? <DeadLink key={ index } href='#' onClick={toggleComingSoon}>{ app.title }</DeadLink> : <NavigationLink key={ index } to={ app.href }>{app.title}</NavigationLink>) 
								:
								null
							}) }
						</NavLinks>
					)}
					<AccessoriesMenu>
						<MenuItem visible={locationHref.indexOf('/vehicle') !== 0}>
							<SearchIcon src={themeContext.searchIcon} onClick={ toggleSearch }/>
						</MenuItem>
						<MenuItem visible={true}>
							<LanguageSelect onClick={toggleComingSoon} src={themeContext.languageIcon}></LanguageSelect>
						</MenuItem>
						<MenuItem visible={locationHref.indexOf('/vehicle') !== 0}>
							<ShoppingCart src={themeContext.cartIcon} onClick={() => {navigate('/cart')}}>
								<ShoppingCartHasParts visible={basket && basket.length > 0} />
								<ShoppingCartIcon src={themeContext.cartIcon} />
							</ShoppingCart>
						</MenuItem>
						<MenuItem visible={true}>
							<UserMenu logout={logout} />
						</MenuItem>
					</AccessoriesMenu>
				</Container>
			</Bar>
			{/* Only show the selector on developer's request */}
			{ selectorVisible && (<SelectorBar />)}	
			<Search visible={ searchVisible } toggle={ toggleSearch } />		
				<Logo src={themeContext.logo} />
		</>
		 );
}
 
export default Header;