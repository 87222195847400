import React from 'react';
import styled from 'styled-components';

const Graphic = styled.img`
	width: 2000px;
`;

const PartsGraphic = ({ src }) => {
    return ( 

        <Graphic src={src} />

     );
}
 
export default PartsGraphic;