const partsLists = [
	{
		id: "PL1",
		title: "Flat Unload Section",
		subtitle: "GT4-7003-AC",
		graphic: "PM000001",
		parts: [
			{
				id: 1,
				annotation: 39,
				partNumber: "702-1069-5",
				name: "Motor, 1HP 3PH 1725 @60Hz Super",
				quantity: 1
			},
			{
				id: 2,
				annotation: 40,
				partNumber: "GT3-1114",
				name: "Gearbox, Sumitomo D612da 683:1 Reducer Model",
				quantity: 1
			},
			{
				id: 3,
				annotation: 41,
				partNumber: "GT1-5062",
				name: "Gearbox Adjustment Plate",
				quantity: 1
			},
			{
				id: 4,
				annotation: 42,
				partNumber: "GT1-5065",
				name: "Cooling Floor Access Door",
				quantity: 1
			},
			{
				id: 5,
				annotation: 43,
				partNumber: "GT1-5015W",
				name: "Hopper Floor Sheets",
				quantity: 1
			},
			{
				id: 6,
				annotation: 44,
				partNumber: "GT1-5044",
				name: "Vertical Base Channel - Left",
				quantity: 3
			},
			{
				id: 7,
				annotation: 45,
				partNumber: "GT1-5005",
				name: "Rolled Plenum Channel",
				quantity: 1
			},
			{
				id: 8,
				annotation: 46,
				partNumber: "GT1-5630",
				name: "Inside Mounting Ring",
				quantity: 3
			},
			{
				id: 9,
				annotation: 47,
				partNumber: "GT1-5684",
				name: "Inside Perforated Galv Wall Sheet - Top",
				quantity: 2
			},
			{
				id: 10,
				annotation: 48,
				partNumber: "GT1-5683",
				name: "Inside Perforated Galv Wall Sheet - Bottom",
				quantity: 2
			},
			{
				id: 11,
				annotation: 49,
				partNumber: "S-8268",
				name: "Bolt, HHCS 1/2-13 x 2 Zinc Grade 8",
				quantity: 96
			},
			{
				id: 12,
				annotation: 50,
				partNumber: "S-3729",
				name: "Nut, Hex 1/2-13 YDP Grade 5",
				quantity: 96
			},
			{
				id: 13,
				annotation: 51,
				partNumber: "S-2120",
				name: "Washer, Flat 1/2 SAE Zinc",
				quantity: 64
			},
			{
				id: 14,
				annotation: 52,
				partNumber: "S-236",
				name: "Washer, Lock Split, 1/2 REG Zinc Plated",
				quantity: 12
			},
			{
				id: 15,
				annotation: 53,
				partNumber: "S-7935",
				name: "Bolt, HHCS 1/2-13 x 1 Zinc Grade 5",
				quantity: 12
			},
			{
				id: 16,
				annotation: 54,
				partNumber: "GT3-1986",
				name: "Gearbox Oil Filter",
				quantity: 1
			},
			{
				id: 17,
				annotation: "-",
				partNumber: "C-2018",
				name: "Gearbox Oil - 5l",
				quantity: 1
			}
		]
	}
]

export default partsLists;