import React from 'react';
import styled from 'styled-components';
import H1 from './typography/H1';
import Subtitle from './typography/Subtitle';

const Divider = styled.div`
    width: 258px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.GreyLight};
    margin-top: 27px;
`;

const PageHeading = ({ title, subtitle }) => {
    return ( 
        <>
            <H1>{ title }</H1>
            <Subtitle>{ subtitle }</Subtitle>
            <Divider></Divider>
        </>
     );
}
 
export default PageHeading;