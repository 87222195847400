import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: ${({ theme }) => theme.sizes.contentContainer};
`;

const PageContainer = ({ children }) => {
    return ( 
        <Container>
            { children }
        </Container>
     );
}
 
export default PageContainer;