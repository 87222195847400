const vehicles = [
    {
        id: 1,
        name: "Class 15X",
        logo: "/images/logo-cimbria.png",
        enabled: false,
    },
    {
        id: 2,
        name: "Class 17X",
        logo: "/images/logo-ap.png",
        enabled: false,
    },
    {
        id: 3,
        name: "Rail Grinder",
        logo: "/images/logo-cumberland.png",
        enabled: false,
    },
    {
        id: 4,
        name: "Class 800",
        logo: "/images/logo-gsi.png",
        enabled: true,
    },
    {
        id: 5,
        name: "Stone Blower",
        logo: "/images/logo-tecno.png",
        enabled: false,
    },
    {
        id: 6,
        name: "Class 57",
        logo: "/images/logo-chicken-boy.png",
        enabled: false,
    }
]

export default vehicles;