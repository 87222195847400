
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 

import './App.css';
import Page from './components/layout/Page';
import HeroPage from './components/layout/HeroPage';
import Login from './pages/Login';
import ServiceInformation from './pages/ServiceInformationTopic';
import PartsCatalogue from './pages/PartsCatalogue';
import Maintenance from './pages/Maintenance';
import MaintenanceDash from './pages/MaintenanceDash';
import Logout from './components/Logout';
import useAuth from './hooks/useAuth';
import useBrand from './hooks/useBrand';
import VehicleSelect from './pages/VehicleSelect';
import Cart from './pages/Cart';
import Dashboard from './pages/Dashboard';

import useBasket from './hooks/useBasket';
import useMaintenance from './hooks/useMaintenance';
import React, { createContext } from 'react';
import useSelectorMenu from './hooks/useSelectorMenu';
import Selector from './pages/Selector';
import TrainingDash from './pages/TrainingDash';
import TrainingCourse from './pages/TrainingCourse';
import TrainingQuiz from './pages/TrainingQuiz';
import useSearch from './hooks/useSearch';
import ComingSoon from './components/ComingSoon';

import useComingSoon from './hooks/useComingSoon';
import Om from './pages/Om';

export const SelectorContext = createContext();
export const ComingSoonContext = createContext();
export const AuthContext = createContext();
export const SearchContext = createContext();
export const BrandContext = createContext();

function App() {

	const brand = useBrand();
	
	const { checklist, items, loadChecklist, completeChecksheet, toggleItem, history, loadHistory, resetChecklist } = useMaintenance();
	//const { authenticatedUser, isAuthenticated, login, logout, setAuthenticatedUser } = useAuth();
	const auth = useAuth();
	const { basket, AddToBasket, RemoveFromBasket, EmptyBasket, IncreaseQuantity, DecreaseQuantity } = useBasket();
	const search = useSearch();
	const menu = useSelectorMenu();
	const comingSoon = useComingSoon();

	React.useEffect(() => {
		loadHistory();
	}, []);

  return (
	  <AuthContext.Provider value={auth}>
		<BrandContext.Provider value={brand}>
		  <SearchContext.Provider value={search}>
			  <SelectorContext.Provider value={menu}>
				  <ComingSoonContext.Provider value={comingSoon}>
					  <ThemeProvider theme={brand.brandFile}>
						  <ComingSoon visible={comingSoon.comingSoonVisible} onCloseClick={comingSoon.toggleComingSoon} />
						  <Router>
							  <Routes>
								  <Route path="logout" element={<Logout />} />
								  <Route path="vehicle" element={<VehicleSelect />} />
								  <Route element={<HeroPage showHeader={false} />}>
									  <Route path="/" exact element={<Login />} />
									  <Route path="login" element={<Login />} />
								  </Route>
								  <Route element={<Page basket={basket} />}>
									  <Route path="si/:topicId" exact element={<ServiceInformation />} />
									  <Route path="mcs" exact element={<MaintenanceDash history={history} />} />
									  <Route path="mcs/:id" element={<Maintenance onComplete={completeChecksheet} loadChecklist={loadChecklist} checklist={checklist} items={items} toggleItem={toggleItem} resetChecklist={resetChecklist} />} />
									  <Route path="epc" element={<PartsCatalogue addToBasket={AddToBasket} />} />
									  <Route path="cart" element={<Cart basket={basket} decreaseQuantity={DecreaseQuantity} increaseQuantity={IncreaseQuantity} removeItem={RemoveFromBasket} emptyBasket={EmptyBasket} />} />
									  <Route path="sel" element={<Selector />} />
									  <Route path="sel/:id1" element={<Selector />} />
									  <Route path="sel/:id1/:id2" element={<Selector />} />
									  <Route path="sel/:id1/:id2/:id3" element={<Selector />} />
									  <Route path="sel/:id1/:id2/:id3/:id4" element={<Selector />} />

									  <Route path="trn" element={<TrainingDash />} />
									  <Route path="trn/course/:courseId" element={<TrainingCourse />} />
									  <Route path="trn/quiz/:quizId" element={<TrainingQuiz />} />

										<Route path="om" element={<Om />} />

								  </Route>
								  <Route element={<HeroPage backgroundImage="/images/background-night-station.jpg" basket={basket} />} >
									  <Route path="si" exact element={<Dashboard />} />
								  </Route>
							  </Routes>
						  </Router>
					  </ThemeProvider>
				  </ComingSoonContext.Provider>
			  </SelectorContext.Provider>
		  </SearchContext.Provider>
		</BrandContext.Provider>
	  </AuthContext.Provider>
  );
}

export default App;
