import React from 'react';

export default function useBasket() {

	const [ basket, setBasket ] = React.useState((localStorage.getItem("basket") && JSON.parse(localStorage.getItem("basket"))) || []);

	React.useEffect(() => {
		if (basket === [] && localStorage.getItem("basket")) {
			setBasket(localStorage.getItem("basket" && JSON.parse(localStorage.getItem("basket"))) || []);
		}
	}, [basket])

	function SaveChanges(newBasket) {
		setBasket(newBasket);
		localStorage.setItem("basket", JSON.stringify(newBasket));
	}

    function AddToBasket(part) {
		let partObject = {...part}; // If this object isn't copied, it modifies the quantity in the actual table!! Super weird.
		let myBasket = [...basket];
		let itemExists = myBasket.filter(p => p.partNumber === partObject.partNumber).length > 0;
		if (!itemExists) {
			myBasket.push(partObject);
			setBasket(myBasket);
			localStorage.setItem("basket", JSON.stringify(myBasket));
		} else {
			let i = myBasket.indexOf(myBasket.filter(p => p.partNumber === partObject.partNumber)[0]);
			myBasket[i].quantity += partObject.quantity;
			SaveChanges(myBasket);
		}
	}

	function IncreaseQuantity(partNumber) {
		let myBasket = [...basket];
		let part = myBasket[myBasket.indexOf(myBasket.filter(p => p.partNumber === partNumber)[0])];
		part.quantity ++;
		SaveChanges(myBasket);
	}

	function DecreaseQuantity(partNumber) {
		let myBasket = [...basket];
		let part = myBasket[myBasket.indexOf(myBasket.filter(p => p.partNumber === partNumber)[0])];
		if (part.quantity > 1)
			part.quantity --;
		SaveChanges(myBasket);
	}

	function RemoveFromBasket(partNumber) {
		let myBasket = [...basket];
		var removed = myBasket.splice(myBasket.indexOf(myBasket.filter(p => p.partNumber === partNumber)[0]), 1);
		console.log(removed);
		SaveChanges(myBasket);
	}

	function EmptyBasket() {
		SaveChanges([]);
	}

	return {basket, AddToBasket, RemoveFromBasket, EmptyBasket, IncreaseQuantity, DecreaseQuantity}

}