import React from 'react';
import styled from 'styled-components';

import Checkbox  from './Checkbox';
import TopicEmbed from './TopicEmbed';

const Item = styled.div`
	display: flex;
	align-items: center;

	margin-bottom: 5px;
`;

const Description = styled.span`
	position: relative;
	display: inline-block;
	margin-left: 20px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.GreySuperLight};
	border-left: solid 3px ${({ theme }) => theme.colors.Primary};
	font-size: ${({ theme }) => theme.fontSizes.xs};
	font-family: ${({ theme }) => theme.fonts.bold};
	padding: 10px;
	color: ${({ theme }) => theme.colors.Black};
	cursor: pointer;
`;

const Caret = styled.img`
	position: absolute;
	display: inline;
	right: 30px;
	top: 13px;
	transform: rotate(${ ({ visible }) => visible ? '90deg' : '0deg' });
	transition: all 0.5s ease;
`;

const ChecklistItem = ({ onClick, checked, description, index, item }) => {

	const [visible, setVisible] = React.useState(false);

	function toggle() {
		setVisible(!visible);
	}

	return ( 
		<>
			<Item>
				<Checkbox checked={checked} onClick={() => {onClick(index)}}/>
				<Description onClick={ toggle }>{description} {item.topicRef && (<Caret visible={visible} src={'/images/icons/icon-caret-down-salmon.svg'}/>)}</Description>
			</Item>
			<TopicEmbed topicId={ item.topicRef } visible={ visible } />
		</>
	 );
}
 
export default ChecklistItem;