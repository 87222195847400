import React from 'react';
import styled from 'styled-components';
//import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const Container = styled.div`
	height: 100%;
	width: 100%;
	& .react-transform-wrapper {
		width: 100%;
		height: 100%;
		& > .react-transform-component {
			width: 100%;
			height: 100%;
			& svg {
				width: 100%;
				height: 100%;
			}
		}
	}
`;

const Diagram = styled.img`
	
`;

const Schematic = ({ src }) => {
	return ( 
	<Container>
		<Diagram src={ src } />
	</Container>
	);
}
 
export default Schematic;