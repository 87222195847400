const users = [
	{
		id: 1,
		username: "steve.beard",
		apps: [0, 3]
	},
	{
		id: 2,
		username: "mark.townsend",
		apps: [0, 1, 2, 3, 4],
	},
	{
		id: 3,
		username: "andrew.mccabe",
		apps: [0, 2, 3]
	}
]

export default users;