import React from 'react';
import styled from 'styled-components';

export default styled.ul`
	list-style-type: disc; 
	padding-left: 30px;
	margin-top: 10px;

	li {
		margin-bottom: 8px;
	}
`;