import React, { useContext } from 'react';
import styled from 'styled-components';

import BrandlessPage from '../components/layout/BrandlessPage';
import P from '../components/typography/P';
import LoginBox from '../components/LoginBox';
import { AuthContext, BrandContext } from '../App';
import { ThemeContext } from 'styled-components';


const Logo = styled.img`
	position: absolute;
	top: 67px;
	right: 0;
	display: inline;
	height: 41px;
`;

const Login = () => {

	const { changeBrand } = useContext(BrandContext);
	const { login } = useContext(AuthContext);

	const themeContext = useContext(ThemeContext);

	return ( 
		<BrandlessPage>
			<Logo src="/images/logo-semcon.svg" />
			<LoginBox login={ login } changeBrand={ changeBrand } />
		</BrandlessPage>
	 );
}
 
export default Login;