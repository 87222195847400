import React from 'react';
import styled from 'styled-components';
import { Overlay, ModalWindow } from './Modal';

// Adding additional styled to the modal window we created for the ModalWindow component
const LbModalWindow = styled(ModalWindow)`
	width: unset;
	padding: 0;
	border-left: solid 5px ${({ theme }) => theme.colors.Primary};

	img {
		margin: 0;
		padding: 0;
	}
`;

const Lightbox = ({ children, isVisible, onClose, width }) => {
	// If the modal window is hidden, no need to show anything at all
	if (!isVisible) return null;
        
	return ( 
            <Overlay onClick={onClose}>
                <LbModalWindow 
					width={width}
					onClick={(e) => e.stopPropagation()}>
                    { children }
                </LbModalWindow>
            </Overlay>
         );
}
 
export default Lightbox;