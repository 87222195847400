import React from 'react';
import styled, { css } from 'styled-components';

const Tooltip = css`
	&:hover:after {
		display: block;
		white-space: nowrap;
		position: absolute;
		bottom: -30px;
		background-color: ${({ theme }) => theme.colors.Primary};
		content: '${({ hoverTitle }) => hoverTitle}';
		padding: 5px;
		color: ${({ theme }) => theme.colors.White};
		font-size: ${({ theme }) => theme.fontSizes.xs};
		opacity: 1;
	}
`;

const Item = styled.div`
	position: absolute;
	display: block;
	z-index: 14;
	bottom: ${({ y }) => y ? y : "0px"};
	left: ${({ x }) => x ? x : "0px"};
	width: ${({ width }) => width ? width : "35px"};
	height: ${({ height }) => height ? height : "35px"};
	background-color: ${({ debug, theme }) => debug ? theme.colors.Primary : 'transparent'};
	cursor: pointer;
	

	${({ hoverTitle }) => hoverTitle && Tooltip};
	
`;

const ImageMapItem = ({ xPos, yPos, height, width, onClick, debug=false, hoverTitle }) => {
	return ( 
		<Item debug={debug} width={width} height={height} y={ yPos } x={xPos} onClick={onClick} hoverTitle={hoverTitle}/>
	 );
}
 
export default ImageMapItem;