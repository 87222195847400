import React from 'react';
import styled from 'styled-components';

export const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
`;

export const ModalWindow = styled.div`
    max-height: 90vh;
    max-width: 94%;
    width: ${({ width }) => (width ? width : '70%')};
    background-color: ${({ theme }) => theme.colors.White};
    margin: auto; // Centre align everything
	z-index: 999;
	transition: all 0.5s ease;
`;

const Modal = ({ children, width, isVisible, onClose}) => {
    // If the modal window is hidden, return null
	if (!isVisible) return null;
        
	return ( 
            <Overlay onClick={onClose}>
                <ModalWindow 
					width={width}
					onClick={(e) => e.stopPropagation()}>
                    { children }
                </ModalWindow>
            </Overlay>
         );
}
 
export default Modal;